import dynamic from "next/dynamic";

export * from "./withShowPdf/WithShowPdf";
export * from "./withInvestorList/WithInvestorList";

export const UpdateItem = dynamic(() => import("./investorItem/UpdateItem"), {
  ssr: false
});

export const ReportItem = dynamic(() => import("./investorItem/ReportItem"), {
  ssr: false
});

export const NewsItem = dynamic(() => import("./investorItem/NewsItem"), {
  ssr: false
});

export const ExpertViewItem = dynamic(() => import("./investorItem/ExpertViewItem"), {
  ssr: false
});

export const ExpertItem = dynamic(() => import("./investorItem/ExpertItem"), {
  ssr: false
});

export const ExpertListItem = dynamic(() => import("./investorItem/ExpertListItem"), {
  ssr: false
});

export const ExpertDetailItem = dynamic(() => import("./investorItem/ExpertDetailItem"), {
  ssr: false
});

export const ResearchItem = dynamic(() => import("./investorItem/ResearchItem"), {
  ssr: false
});

export const BannerItem = dynamic(() => import("./bannerItem/BannerItem"), {
  ssr: false
});

export const Updates = dynamic(() => import("./updates/Updates"), {
  ssr: false
});

export const News = dynamic(() => import("./news/News"), {
  ssr: false
});

export const Research = dynamic(() => import("./research/Research"), {
  ssr: false
});

export const Expert = dynamic(() => import("./expert/Expert"), {
  ssr: false
});

export const FeaturedExperts = dynamic(() => import("./expert/FeaturedExperts"), {
  ssr: false
});

export const RecommendBox = dynamic(() => import("./recommendBox/RecommendBox"), {
  ssr: false
});

export const RecommendNewsItem = dynamic(() => import("./recommendBox/RecommendNewsItem"), {
  ssr: false
});

export const RecommendResearchItem = dynamic(() => import("./recommendBox/RecommendResearchItem"), {
  ssr: false
});

export const RecommendUpdateItem = dynamic(() => import("./recommendBox/RecommendUpdateItem"), {
  ssr: false
});

export const RecommendExpertViewItem = dynamic(
  () => import("./recommendBox/RecommendExpertViewItem"),
  {
    ssr: false
  }
);

export const RecommendExpertItem = dynamic(() => import("./recommendBox/RecommendExpertItem"), {
  ssr: false
});

export const LabelText = dynamic(() => import("./labelText/LabelText"), {
  ssr: false
});

export const ExpertIntro = dynamic(() => import("./expertIntro/ExpertIntro"), {
  ssr: false
});

export const DetailMore = dynamic(() => import("./detailMore/DetailMore"), {
  ssr: false
});

export const ListRightBox = dynamic(() => import("./listRightBox/ListRightBox"), {
  ssr: false
});

export const UpdatesList = dynamic(() => import("./updates/UpdatesList"), {
  ssr: false
});

export const ReportList = dynamic(() => import("./updates/ReportList"), {
  ssr: false
});
