"use client";
import React, { useEffect, useMemo, useState } from "react";
import { Breadcrumb, Spin, Tabs } from "antd";
import styles from "@aspen/theme/saas/ventureDetail.module.less";
import {
  i18nUtil,
  reportEvent,
  GA_EVENT_TAG,
  SAAS,
  SAAS_COMMON_CACHE_PRIORITY_KEYS,
  SAAS_GA_EVENT_NAME,
  SAAS_VENTURE_DETAIL_TAB,
  SAAS_VENTURE_TAB
} from "@aspen/libs";
import Link from "next/link";
import Image from "next/image";
import { ActionBox, VentureUpdateList, EditorView } from "../../../index";
import { getSaasVentureDetail, saasInterestVenture } from "@aspen/services";
import { useRouter } from "next/router";
import { useSaasCachePriorityData } from "@aspen/hooks";
import { CommonButton } from "@saas/ui";
import { message } from "@aspen/ui";
import { IResSaasVenture } from "@aspen/model";

const star = require("@aspen/assets/images/saas/star_white.webp");
const notStar = require("@aspen/assets/images/saas/notStar_purple.webp");

export const PageVentureDetail: React.FC = () => {
  const intl = i18nUtil.t();
  const router = useRouter();
  const id = (router?.query?.id ?? "") as string;
  const [venture, setVenture] = useState<IResSaasVenture | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [productAmount, setProductAmount] = useState(0);
  const [currentTab, setCurrentTab] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    const defaultTab = router?.query?.currentTab?.toString() ?? SAAS_VENTURE_DETAIL_TAB.detail;
    setCurrentTab(defaultTab);
  }, [router?.query?.currentTab]);

  const { data: interestVentureIds }: any = useSaasCachePriorityData<any>(
    SAAS_COMMON_CACHE_PRIORITY_KEYS.saasInterestVentures
  );

  useEffect(() => {
    if ((interestVentureIds ?? []).includes(id)) {
      setIsFavorite(true);
    }
  }, [interestVentureIds, id]);

  useEffect(() => {
    const defaultTab = router?.query?.currentTab?.toString() ?? SAAS_VENTURE_DETAIL_TAB.detail;
    setCurrentTab(defaultTab);
  }, [router?.query?.currentTab]);

  const tabItems = [
    {
      label: intl["saas.detail"],
      key: SAAS_VENTURE_DETAIL_TAB.detail
    },
    {
      label: intl["saas.updates"],
      key: SAAS_VENTURE_DETAIL_TAB.update
    }
  ];

  const { data: walletInfo }: any = useSaasCachePriorityData<any>(
    SAAS_COMMON_CACHE_PRIORITY_KEYS.saasWalletInfo
  );

  useEffect(() => {
    const findWallet = (walletInfo?.venturePositionList ?? []).find(
      (item) => item.ventureId === id
    );
    if (findWallet) {
      setProductAmount(findWallet.fairValue);
    }
  }, [walletInfo?.venturePositionList]);

  const fetchVentures = () => {
    getSaasVentureDetail({
      productId: id
    })
      .then((res) => {
        if (res?.code == "0") {
          setVenture(res?.data);
        } else {
          message.error(intl[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      fetchVentures();
    }
  }, [id]);

  const handleChangeTabs = (value) => {
    setCurrentTab(value);
    router?.replace({ query: { id, currentTab: value } }, undefined, { scroll: false });
  };

  const purchaseInformation = useMemo(() => {
    try {
      const info = JSON.parse(venture?.purchaseInformation ?? "");
      const data: { title: string; subTitle: string }[] = [];
      (Object.keys(info) ?? []).map((key) => {
        if (key.startsWith("title")) {
          const index = key.split("title");
          data.push({ title: info[key], subTitle: info[`subtitle${index[1]}`] });
        }
      });
      return data;
    } catch (e) {
      return [];
    }
  }, [venture?.purchaseInformation]);

  const handleChangeFavorite = () => {
    const action = !isFavorite;
    reportEvent({
      moduleName: SAAS_GA_EVENT_NAME.product.interested,
      joinedTag: GA_EVENT_TAG.Click,
      detailParams: {
        id,
        interested: action,
        type: "venture"
      }
    });
    saasInterestVenture({
      id,
      interested: action
    }).then((res) => {
      if (res?.code == "0") {
        if (action) {
          message.success(intl["saas.ventures.add.success"]);
        } else {
          message.success(intl["saas.ventures.remove.success"]);
        }

        setIsFavorite(action);
      } else {
        message.error(intl[res?.msg] ?? res?.msg);
      }
    });
  };

  const status = useMemo(() => {
    const now = new Date().getTime();
    const endTime = venture?.endTime ?? 0;
    const startTime = venture?.startTime ?? 0;
    if (now >= endTime) {
      return "closed";
    }
    if (now < startTime) {
      return "comingSoon";
    }
    return "ing";
  }, [venture?.endTime]);

  const isClosed = status === "closed";
  const productImage = isClosed
    ? venture?.listPictureStopAddressWeb
    : venture?.listPictureOnSaleAddressWeb;
  return (
    <div className={styles.detail}>
      <Breadcrumb>
        <Breadcrumb.Item className={styles.breadLinkText}>
          <Link
            href={{
              pathname: SAAS.VENTURES,
              query: {
                currentTab:
                  venture?.shares === 0 ? SAAS_VENTURE_TAB.primary : SAAS_VENTURE_TAB.secondary
              }
            }}>
            {intl["saas.ventures"]}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className={styles.breadText}>
          {intl["saas.ventures.details"]}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={isLoading}>
        <div className={styles.detailContent}>
          <div className={styles.top}>
            <div className={styles.imgBox}>
              <Image
                unoptimized
                width="698"
                height="491"
                style={{ borderRadius: 12 }}
                alt={productImage ?? ""}
                src={productImage ?? ""}
                layout="fixed"
                objectFit="cover"
                objectPosition="center"
              />
            </div>
            <div className={styles.topRight}>
              <div title={venture?.name} className={styles.titleText}>
                {venture?.name}
              </div>
              <div style={{ display: "flex" }} className={styles.typeLabelBox}>
                <div className={styles.typeLabel}>
                  <div>{intl[`saas.venture.capital.${venture?.assetType}`]}</div>
                </div>
                <CommonButton
                  onClick={handleChangeFavorite}
                  className={styles.favoriteBtn}
                  btntype={isFavorite ? "solid" : "transparent"}>
                  <Image
                    unoptimized
                    width={19}
                    height={19}
                    src={isFavorite ? star : notStar}
                    alt=""
                  />
                  <div style={{ marginLeft: 6 }}>{intl["saas.button.favorite"]}</div>
                </CommonButton>
              </div>
              <div className={styles.cardBox}>
                <div className={styles.propBox}>
                  {purchaseInformation.map((item, index) => (
                    <div key={`${item?.title}${index}`} className={styles.propItemBox}>
                      <div title={item?.title} className={styles.itemTitle}>
                        {item?.title}
                      </div>
                      <div title={item?.subTitle} className={styles.itemValue}>
                        {item?.subTitle}
                      </div>
                    </div>
                  ))}
                </div>
                <ActionBox
                  type="venture"
                  status={status}
                  data={venture}
                  productAmount={productAmount}
                />
              </div>
            </div>
          </div>
          <div className={styles.overview}>
            <Tabs
              items={tabItems}
              className={styles.tab}
              activeKey={currentTab}
              onChange={handleChangeTabs}
            />
            <div className={styles.tabContent}>
              {currentTab === SAAS_VENTURE_DETAIL_TAB.detail && (
                <div className={styles.detailBox}>
                  <EditorView value={venture?.detailInformation ?? ""} />
                </div>
              )}

              <div
                style={{
                  marginTop: 32,
                  display: currentTab === SAAS_VENTURE_DETAIL_TAB.update ? "block" : "none"
                }}>
                <VentureUpdateList productId={id} />
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};
