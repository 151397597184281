"use client";
import React, { useEffect, useMemo, useState } from "react";
import { Breadcrumb, message, Spin } from "antd";
import styles from "@aspen/theme/saas/ventureDetail.module.less";
import { SAAS, SAAS_COMMON_CACHE_PRIORITY_KEYS, i18nUtil } from "@aspen/libs";
import Link from "next/link";
import Image from "next/image";
import { ActionBox, EditorView } from "../../../index";
import { getSaasStrategyDetail } from "@aspen/services";
import { useRouter } from "next/router";
import { useSaasCachePriorityData } from "@aspen/hooks";

export const PageStrategyDetail: React.FC = () => {
  const intl = i18nUtil.t();
  const router = useRouter();
  const id = (router?.query?.id ?? "") as string;
  const [strategy, setStrategy] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [productAmount, setProductAmount] = useState(0);

  const { data: walletInfo }: any = useSaasCachePriorityData<any>(
    SAAS_COMMON_CACHE_PRIORITY_KEYS.saasWalletInfo
  );

  useEffect(() => {
    const findWallet = (walletInfo?.strategyPositionList ?? []).find(
      (item) => item.strategyId === id
    );
    if (findWallet) {
      setProductAmount(findWallet.fairValue);
    }
  }, [walletInfo?.strategyPositionList]);

  const fetchStrategy = () => {
    getSaasStrategyDetail({
      productId: id
    })
      .then((res) => {
        if (res?.code == "0") {
          setStrategy(res?.data);
        } else {
          message.error(intl[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      fetchStrategy();
    }
  }, [id]);

  const purchaseInformation = useMemo(() => {
    try {
      const info = JSON.parse(strategy?.purchaseInformation);
      const data: { title: string; subTitle: string }[] = [];
      (Object.keys(info) ?? []).map((key) => {
        if (key.startsWith("title")) {
          const index = key.split("title");
          data.push({ title: info[key], subTitle: info[`subtitle${index[1]}`] });
        }
      });
      return data;
    } catch (e) {
      return [];
    }
  }, [strategy?.purchaseInformation]);

  const status = useMemo(() => {
    const now = new Date().getTime();
    const endTime = strategy?.endTime;
    const startTime = strategy?.startTime;
    if (now >= endTime) {
      return "closed";
    }
    if (now < startTime) {
      return "comingSoon";
    }
    return "ing";
  }, [strategy?.endTime]);

  const isClosed = status === "closed";
  const src = isClosed
    ? strategy?.listPictureStopAddressWeb
    : strategy?.listPictureOnSaleAddressWeb;
  return (
    <div className={styles.detail}>
      <Breadcrumb>
        <Breadcrumb.Item className={styles.breadLinkText}>
          <Link
            href={{
              pathname: SAAS.STRATEGIES
            }}>
            {intl["saas.strategy"]}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className={styles.breadText}>
          {intl["saas.ventures.details"]}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={isLoading}>
        <div className={styles.detailContent}>
          <div className={styles.top}>
            <div className={styles.imgBox}>
              <Image
                unoptimized
                width="698"
                height="491"
                style={{ borderRadius: 12, objectFit: "cover", objectPosition: "center" }}
                alt={src}
                src={src}
              />
            </div>
            <div className={styles.topRight}>
              <div className={styles.titleText}>{strategy?.name}</div>
              <div style={{ display: "flex" }} className={styles.typeLabelBox}>
                <div className={styles.typeLabel}>
                  <div>{intl[`saas.strategy.type.${strategy?.type}`]}</div>
                </div>
              </div>
              <div className={styles.cardBox}>
                <div className={styles.propBox}>
                  {purchaseInformation.map((item) => (
                    <div key={item?.title} className={styles.propItemBox}>
                      <div title={item?.title} className={styles.itemTitle}>
                        {item?.title}
                      </div>
                      <div title={item?.subTitle} className={styles.itemValue}>
                        {item?.subTitle}
                      </div>
                    </div>
                  ))}
                </div>
                <ActionBox
                  type="strategy"
                  status={status}
                  data={strategy}
                  productAmount={productAmount}
                />
              </div>
            </div>
          </div>
          <div className={styles.overview}>
            <div className={styles.detailBox}>
              <EditorView value={strategy?.detailInformation} />
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};
