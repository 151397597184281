import React, { useState } from "react";
import styles from "./WithShowPdf.module.less";
import { getSaasFileSignature } from "@aspen/services";
import { message } from "@aspen/ui";
import { i18nUtil } from "@aspen/libs";

interface IProps {
  [key: string]: any; // 保留此any, HOC组件，props可能为任意类型
}

export const WithShowPdf = (WrappedComponent) => {
  const WithShowPdf: React.FC<IProps> = (props: IProps) => {
    const intl = i18nUtil.t();
    const [showPdf, setShowPdf] = useState(false);
    const [pdfLink, setPdfLink] = useState("");

    const getPathSignature = (link, cb) => {
      getSaasFileSignature(link).then((res) => {
        if (res?.code == "0") {
          cb(res?.data);
        } else {
          message.error(intl["global.access.denied"]);
        }
      });
    };

    /**
     * 从给定的URL获取Blob数据并通过回调函数返回。
     * @param url - 要获取数据的URL
     * @param cb - 获取数据后的回调函数
     */
    const getBlob = (url, cb) => {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (xhr.status === 200) {
          cb(xhr.response);
        } else {
          message.error(xhr.statusText ?? intl["saas.download.fail"]);
        }
      };
      xhr.send();
    };

    /**
     * 将Blob数据保存为指定的文件名。
     * @param blob - 要保存的Blob数据
     * @param filename - 保存的文件名
     */
    const saveAs = (blob, filename) => {
      const nav = window.navigator as any;
      if (nav.msSaveOrOpenBlob) {
        nav.msSaveBlob(blob, filename);
      } else {
        let link = document.createElement("a");
        let body = document.querySelector("body");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.style.display = "none";
        body?.appendChild(link);
        link.click();
        body?.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }
      message.success(intl["saas.download.success"]);
    };
    const downloadPdf = (link, fileName) => {
      getPathSignature(link, (signData) => {
        getBlob(signData, (blob) => {
          saveAs(blob, fileName);
        });
      });
    };

    const openPdf = (link) => {
      getPathSignature(link, (signData) => {
        setShowPdf(true);
        setPdfLink(signData);
      });
    };

    return (
      <>
        <WrappedComponent openPdf={openPdf} downloadPdf={downloadPdf} {...props} />
        {showPdf ? (
          <div
            className={styles.pdfWrapper}
            onClick={() => {
              setShowPdf(false);
            }}>
            <iframe
              id="_iframe"
              src={pdfLink}
              width="60%"
              height="100%"
              className={styles.iframe}
            />
          </div>
        ) : null}
      </>
    );
  };
  return WithShowPdf;
};
