import React, { useMemo } from "react";
import ReactECharts from "echarts-for-react";
import { BENCHMARK_CURRENCY_SYMBOL, decimalPointNoMoreX, numberToThousands } from "@aspen/libs";

interface IProps {
  data: { value: number; name: string; percent: string }[];
  colors?: any[]; // 不传颜色取echart默认颜色
}

const allocationImg = require("@aspen/assets/images/saas/allocationImg.png")?.default?.src;

export const PieChart: React.FC<IProps> = (props: IProps) => {
  const { data, colors } = props;
  const option = useMemo(() => {
    return {
      color: colors ?? [
        "#5470c6",
        "#91cc75",
        "#fac858",
        "#ee6666",
        "#73c0de",
        "#3ba272",
        "#fc8452",
        "#9a60b4",
        "#ea7ccc"
      ],
      tooltip: {
        trigger: "item",
        textStyle: {
          color: "rgba(0, 0, 0, 0.7)",
          fontSize: 12
        },
        formatter: (params: any) => {
          return `${params.data.name}(${
            params.data.percent
          })  ${BENCHMARK_CURRENCY_SYMBOL}${numberToThousands(
            decimalPointNoMoreX(params.data.value, 2)
          )}`;
        }
      },
      legend: {
        right: 16,
        top: "center",
        orient: "verticalAlign",
        itemWidth: 14,
        itemHeight: 14,
        borderRadius: 14,
        itemGap: 24,
        textStyle: { color: "#fff" },
        icon: "circle",
        formatter: (name: string) => {
          const singleData = data.find((item) => item.name == name);
          if (!singleData) {
            return name;
          }
          return `${name} (${singleData?.percent})   ${BENCHMARK_CURRENCY_SYMBOL}${numberToThousands(
            decimalPointNoMoreX(singleData?.value, 2)
          )}`;
        },
        tooltip: {
          show: false
        }
      },
      series: [
        {
          type: "pie",
          radius: ["56%", "80%"],
          center: [96, "50%"],
          emptyCircleStyle: {
            opacity: 0.5
          },
          itemStyle: {
            borderRadius: 10,
            borderColor: "#000",
            borderWidth: 8
          },
          label: {
            show: true,
            formatter: "",
            height: 73,
            width: 73,
            backgroundColor: {
              image: allocationImg
            },
            borderRadius: 37,
            color: "#fff",
            position: "center"
          },
          emphasis: {
            label: {
              show: true
            }
          },
          labelLine: {
            show: false
          },
          data
        }
      ]
    };
  }, [data]);

  return <ReactECharts option={option} />;
};
