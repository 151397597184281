"use client";
import React, { useEffect, useState } from "react";
import { Empty, Space, Spin } from "antd";
import styles from "@aspen/theme/saas/authorDetail.module.less";
import { useRouter } from "next/router";
import {
  RecommendBox,
  ExpertDetailItem,
  ExpertIntro,
  LabelText,
  WithInvestorList
} from "../../../../index";
import Image from "next/image";
import default_avatar from "@aspen/assets/images/saas/saas_avatar.webp";
import clsx from "clsx";
import { AsPagination, message } from "@aspen/ui";
import {
  IPagerInfo,
  AsInformationQueryRequest,
  AsInformationVO,
  AuthorCustomerVO
} from "@aspen/model";
import {
  i18nUtil,
  SAAS_DEFAULT_INVESTOR_LIMIT,
  SAAS_INIT_PAGE_INFO,
  SAAS_INVESTOR_PARAMS_ACTION,
  SAAS_INVESTOR_PARAMS_INFORMATION_TYPE,
  SAAS_INVESTOR_PARAMS_TYPE
} from "@aspen/libs";
import { getSaasExpertsDetail } from "@aspen/services";

interface IProps {
  data: AsInformationVO[];
  isLoading: boolean;
  pagerInfo: IPagerInfo;
  fetchInvestor: (pagerInfo: IPagerInfo, params?: AsInformationQueryRequest) => void;
}

const ExpertDetail: React.FC<IProps> = (props: IProps) => {
  const intl = i18nUtil.t();
  const router = useRouter();
  const id = (router?.query?.id ?? "") as string;
  const [expert, setExpert] = useState<any>(null);
  const [isExpertLoading, setIsExpertLoading] = useState(false);

  const { data, isLoading, pagerInfo, fetchInvestor } = props;

  const fetchExpertDetail = () => {
    setIsExpertLoading(true);
    getSaasExpertsDetail(id)
      .then((res) => {
        if (res?.code == "0") {
          setExpert(res?.data);
        } else {
          message.error(intl[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setIsExpertLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      fetchExpertDetail();
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchInvestor(SAAS_INIT_PAGE_INFO, {
        action: SAAS_INVESTOR_PARAMS_ACTION.ALL,
        type: SAAS_INVESTOR_PARAMS_TYPE.EXPERTS,
        informationType: SAAS_INVESTOR_PARAMS_INFORMATION_TYPE.expert,
        kolIds: id,
        nextToken: SAAS_INIT_PAGE_INFO.nextToken,
        limit: SAAS_DEFAULT_INVESTOR_LIMIT
      });
    }
  }, [id]);

  return (
    <div className={clsx(styles.detail, "saasCustomer")}>
      <div className="saasCustomerTop" style={{ height: 572 }}>
        <div
          className={styles.detailBg}
          style={{ backgroundImage: `url("${expert?.backGroundImage}")` }}
        />
      </div>
      <div className={styles.detailBox}>
        <Spin spinning={isExpertLoading}>
          <div className={styles.authorDetailBox}>
            <div className={styles.avatarBox}>
              <Image
                unoptimized
                alt=""
                src={expert?.avatar ?? default_avatar}
                width={148}
                height={148}
              />
            </div>
            <div className={styles.authorName}>{expert?.name}</div>
            <div className={styles.authorLabelBox}>
              {(expert?.keyWords ?? [])?.map((i) => <LabelText key={i} title={i} type="author" />)}
            </div>
            <div className={styles.authorDesc}>
              <div className={styles.authorDescTitle}>{intl["saas.author.about"]}</div>
              <ExpertIntro key={expert?.intro} intro={expert?.intro ?? ""} />
            </div>
          </div>
        </Spin>
        <div className={styles.detailContent}>
          <Spin spinning={isLoading}>
            <div className={styles.contentLeft}>
              <div className={styles.headerBox}>
                <p className={styles.headerTitle}>{intl["saas.expert"]}</p>
              </div>
              {data.length === 0 && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={intl["saas.empty.desc"]} />
              )}
              {data.map((item) => (
                <div className={styles.newsItem} key={item.id}>
                  <ExpertDetailItem data={item} />
                </div>
              ))}
              {data.length > 0 && (
                <AsPagination
                  pagerInfo={pagerInfo}
                  onPageChange={(_pagerInfo) => {
                    fetchInvestor(_pagerInfo, {
                      action: SAAS_INVESTOR_PARAMS_ACTION.ALL,
                      type: SAAS_INVESTOR_PARAMS_TYPE.EXPERTS,
                      informationType: SAAS_INVESTOR_PARAMS_INFORMATION_TYPE.expert,
                      kolIds: id,
                      nextToken: _pagerInfo.nextToken,
                      limit: SAAS_DEFAULT_INVESTOR_LIMIT
                    });
                  }}
                />
              )}
            </div>
          </Spin>
          <Space size={16} direction="vertical" className={styles.contentRight}>
            <RecommendBox type="otherExperts" authorId={id} />
          </Space>
        </div>
      </div>
    </div>
  );
};

export const PageInvestorAuthorDetail = WithInvestorList(React.memo(ExpertDetail), {});
