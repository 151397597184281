/*
 * NOTE:
 * 1. 新增/改变路由，需要在增加/检查权限配置
 */
import { SAAS, IRole } from "@aspen/libs";

/*
 * desc： 路由/角色/配置
 * 不配置路由权限或者为空，即不限角色role
 * 只有在needAuthenticate为true的情况下才需要配置role
 *
 * 根据域名判断，登陆路由模块是否存在 （域名中存在partner时为代理商CMS登陆, 存在service时为SAS登陆 仅显示登陆login路由 本地调试代理商的话需要partner改为local）
 */
const loginRouteConfig: IRole = {
  [SAAS.LOGIN]: {
    exact: true,
    needAuthenticated: false,
    role: [],
    titleKey: "router.title.login",
    titleDescription: ""
  }
};

export const afterLoginRouteConfig: IRole = {
  [SAAS.VENTURES]: {
    exact: true,
    role: [],
    needAuthenticated: true,
    titleKey: "saas.router.title.ventures",
    titleDescription: ""
  },
  [SAAS.VENTURES_DETAIL]: {
    exact: true,
    role: [],
    needAuthenticated: true,
    titleKey: "saas.router.title.ventures",
    titleDescription: ""
  },
  [SAAS.STRATEGIES]: {
    exact: true,
    role: [],
    needAuthenticated: true,
    titleKey: "saas.router.title.strategies",
    titleDescription: ""
  },
  [SAAS.STRATEGIES_DETAIL]: {
    exact: true,
    role: [],
    needAuthenticated: true,
    titleKey: "saas.router.title.strategies",
    titleDescription: ""
  },
  [SAAS.INVESTOR]: {
    exact: true,
    role: [],
    needAuthenticated: true,
    titleKey: "saas.router.title.investor",
    titleDescription: ""
  },
  [SAAS.INVESTOR_DETAIL]: {
    exact: true,
    role: [],
    needAuthenticated: true,
    titleKey: "saas.router.title.investor",
    titleDescription: ""
  },
  [SAAS.INVESTOR_AUTHOR_LIST]: {
    exact: true,
    role: [],
    needAuthenticated: true,
    titleKey: "saas.router.title.investor",
    titleDescription: ""
  },
  [SAAS.INVESTOR_AUTHOR_DETAIL]: {
    exact: true,
    role: [],
    needAuthenticated: true,
    titleKey: "saas.router.title.investor",
    titleDescription: ""
  },
  [SAAS.INVESTMENT]: {
    exact: true,
    role: [],
    needAuthenticated: true,
    titleKey: "saas.router.title.investment",
    titleDescription: ""
  },
  [SAAS.WALLET]: {
    exact: true,
    role: [],
    needAuthenticated: true,
    titleKey: "saas.router.title.wallet",
    titleDescription: ""
  }
};

export const routeConfig = Object.assign(loginRouteConfig, afterLoginRouteConfig);
