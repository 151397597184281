"use client";
import React, { useEffect, useState } from "react";
import styles from "@aspen/theme/saas/authorList.module.less";
import { ExpertListItem } from "../../../index";
import { message, AsPagination } from "@aspen/ui";
import { IPagerInfo, AuthorCustomerVO } from "@aspen/model";
import {
  i18nUtil,
  SAAS_DEFAULT_INVESTOR_LIMIT,
  SAAS_INIT_PAGE_INFO,
  SAAS_INVESTOR_PARAMS_EXPERT_TYPE
} from "@aspen/libs";
import { getSaasExperts } from "@aspen/services";
import { Spin } from "antd";

export const PageInvestorAuthor: React.FC = () => {
  const intl = i18nUtil.t();
  const [data, setData] = useState<AuthorCustomerVO[]>([]);
  const [chosenData, setChosenData] = useState<AuthorCustomerVO[]>([]);
  const [pagerInfo, setPagerInfo] = useState<IPagerInfo>(SAAS_INIT_PAGE_INFO);
  const [chosenPagerInfo, setChosenPagerInfo] = useState<IPagerInfo>(SAAS_INIT_PAGE_INFO);
  const [isLoading, setIsLoading] = useState(true);
  const [isChosenLoading, setChosenIsLoading] = useState(true);
  const fetchExperts = (_pagerInfo) => {
    setIsLoading(true);
    getSaasExperts({
      expertsType: SAAS_INVESTOR_PARAMS_EXPERT_TYPE.ALL,
      nextToken: pagerInfo.nextToken,
      limit: SAAS_DEFAULT_INVESTOR_LIMIT,
      count: 3
    })
      .then((res) => {
        if (res?.code == "0") {
          const isLastData =
            res?.data?.rows?.length === SAAS_DEFAULT_INVESTOR_LIMIT &&
            res?.data?.totalCount === _pagerInfo.page * SAAS_DEFAULT_INVESTOR_LIMIT;
          setPagerInfo({
            page: _pagerInfo.page,
            nextToken: isLastData ? "" : res?.data?.nextToken ?? ""
          });
          setData(res?.data?.rows ?? []);
        } else {
          message.error(intl[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const fetchChosenExperts = (_pagerInfo) => {
    setChosenIsLoading(true);
    getSaasExperts({
      expertsType: SAAS_INVESTOR_PARAMS_EXPERT_TYPE.CHOSEN,
      nextToken: chosenPagerInfo.nextToken,
      limit: SAAS_DEFAULT_INVESTOR_LIMIT,
      count: 3
    })
      .then((res) => {
        if (res?.code == "0") {
          const isLastData =
            res?.data?.rows?.length === SAAS_DEFAULT_INVESTOR_LIMIT &&
            res?.data?.totalCount === _pagerInfo.page * SAAS_DEFAULT_INVESTOR_LIMIT;
          setChosenPagerInfo({
            page: _pagerInfo.page,
            nextToken: isLastData ? "" : res?.data?.nextToken ?? ""
          });
          setChosenData(res?.data?.rows ?? []);
        } else {
          message.error(intl[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setChosenIsLoading(false);
      });
  };

  useEffect(() => {
    fetchExperts(SAAS_INIT_PAGE_INFO);
    fetchChosenExperts(SAAS_INIT_PAGE_INFO);
  }, []);

  return (
    <section className="saasCustomContainer">
      <p className={styles.headerText}>{intl["saas.investor"]}</p>
      <div className={styles.contentBox}>
        <div className={styles.authorListBox}>
          <Spin spinning={isLoading}>
            <div className={styles.authorListLeftBox}>
              <div className={styles.contentTitle}>{intl["saas.all.experts"]}</div>
              <div className={styles.authorListLeft}>
                {data.map((item) => (
                  <div className={styles.authorListItemBox} key={item.id}>
                    <ExpertListItem data={item} viewDesc />
                  </div>
                ))}
              </div>
              {data.length > 0 && (
                <AsPagination
                  pagerInfo={pagerInfo}
                  onPageChange={(_pagerInfo) => {
                    fetchExperts(_pagerInfo);
                  }}
                />
              )}
            </div>
          </Spin>
          <div className={styles.authorListRight}>
            <div className={styles.authorListRightTitle}>{intl["saas.author.list.title"]}</div>
            <Spin spinning={isChosenLoading}>
              {chosenData.map((item) => (
                <div className={styles.authorListRightItemBox} key={item.id}>
                  <ExpertListItem data={item} />
                </div>
              ))}
              {chosenData.length > 0 && (
                <AsPagination
                  pagerInfo={chosenPagerInfo}
                  onPageChange={(_pagerInfo) => {
                    fetchChosenExperts(_pagerInfo);
                  }}
                />
              )}
            </Spin>
          </div>
        </div>
      </div>
    </section>
  );
};
