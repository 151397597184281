"use client";
import React, { useEffect, useMemo, useState } from "react";
import { Breadcrumb, Space, Spin } from "antd";
import styles from "@aspen/theme/saas/investorDetail.module.less";
import Link from "next/link";
import {
  i18nUtil,
  transformTime,
  SAAS,
  SAAS_INVESTOR_PARAMS_INFORMATION_TYPE,
  SAAS_INVESTOR_TAB
} from "@aspen/libs";
import { useRouter } from "next/router";
import { CloudDownloadOutlined, EditOutlined } from "@ant-design/icons";
import { DetailMore, LabelText, ListRightBox, EditorView, WithShowPdf } from "../../../index";
import { getSaasInvestorPortalDetail, saasMarkReadInvestorPortal } from "@aspen/services";
import defaultAvatar from "@aspen/assets/images/avatar.png";
import Image from "next/image";
import { AsInformationVO } from "@aspen/model";
import clsx from "clsx";

interface IProps {
  openPdf: (pdfLink?: string) => void;
}

const Detail: React.FC<IProps> = (props: IProps) => {
  const intl = i18nUtil.t();
  const router = useRouter();
  const type = (router?.query?.type ?? "") as string;
  const id = (router?.query?.id ?? "") as string;

  const { openPdf } = props;

  const types = useMemo(() => {
    switch (type) {
      case SAAS_INVESTOR_TAB.updates:
        return ["news", "research", "expert"];
      case SAAS_INVESTOR_TAB.research:
        return ["news", "expert", "updates"];
      case SAAS_INVESTOR_TAB.expert:
        return ["otherExperts"];
      default:
        return ["research", "expert", "updates"];
    }
  }, [type]);
  const [data, setData] = useState<AsInformationVO | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const markRead = (id, type) => {
    saasMarkReadInvestorPortal({
      id,
      informationType: type
    });
  };

  const fetchInvestorDetail = () => {
    setIsLoading(true);
    getSaasInvestorPortalDetail({
      id,
      informationType: SAAS_INVESTOR_PARAMS_INFORMATION_TYPE[type]
    })
      .then((res) => {
        if (res.code == "0") {
          const data = res?.data;
          if (
            data?.informationType &&
            [SAAS_INVESTOR_PARAMS_INFORMATION_TYPE.updates].includes(data?.informationType) &&
            !data?.hasRead
          ) {
            markRead(data?.id, data?.informationType);
          }
          setData(data ?? null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      fetchInvestorDetail();
    }
  }, [id]);

  const handleOpenPdf = () => {
    openPdf && openPdf(data?.reportLink);
  };

  return (
    <div className={clsx(styles.detail, "saasCustomContainer")}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item className={styles.breadLinkText}>
          <Link
            href={{
              pathname: SAAS.INVESTOR,
              query: {
                currentTab: type
              }
            }}>
            <span>{intl[`saas.${type}`]}</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className={styles.breadText}>
          {intl["saas.ventures.details"]}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={isLoading}>
        <div className={styles.detailContent}>
          <div className={styles.contentLeft}>
            <div className={styles.contentLeftTop}>
              <p className={styles.contentTitle}>{data?.title}</p>
              <div className={styles.contentSubTitle}>
                <Space size={16} className={styles.subTitleBox}>
                  {[
                    SAAS_INVESTOR_TAB.news,
                    SAAS_INVESTOR_TAB.report,
                    SAAS_INVESTOR_TAB.expert
                  ].includes(type) &&
                    (data?.keyWords ?? []).map((label) => <LabelText key={label} title={label} />)}
                  {[SAAS_INVESTOR_TAB.news, SAAS_INVESTOR_TAB.research].includes(type) && (
                    <div className={styles.contentTime}>
                      <EditOutlined style={{ marginRight: 6 }} />
                      {intl["saas.by"]} {data?.publisherEmail}
                    </div>
                  )}
                  <div className={styles.contentTime}>
                    {transformTime(Number(data?.publishTime))}
                  </div>
                  {type === SAAS_INVESTOR_TAB.updates &&
                    (data?.updatesProductNames ?? []).map((label, index) => (
                      <>
                        <LabelText
                          productId={data?.updatesProductIds?.[index]}
                          key={label}
                          title={label}
                          type="product"
                        />
                        {index !== (data?.updatesProductNames ?? []).length - 1 && (
                          <div className={styles.contentDivider}>|</div>
                        )}
                      </>
                    ))}
                </Space>

                {type === SAAS_INVESTOR_TAB.news && (
                  <div className={styles.contentTime}>
                    {intl["saas.source"]}：{data?.source}
                  </div>
                )}
                {type === SAAS_INVESTOR_TAB.research && data?.reportName && data?.reportLink && (
                  <div onClick={handleOpenPdf} className={styles.researchLink}>
                    <CloudDownloadOutlined style={{ marginRight: 5 }} />
                    {data?.reportName}
                  </div>
                )}
                {type === SAAS_INVESTOR_TAB.expert && (
                  <div className={styles.contentAuthor}>
                    <Image
                      unoptimized
                      src={data?.authorCustomer?.avatar || defaultAvatar}
                      alt=""
                      width={12}
                      height={12}
                    />
                    <div style={{ marginLeft: 6 }}>
                      {intl["saas.by"]} {data?.authorCustomer?.name}
                    </div>
                  </div>
                )}
              </div>
              {type === SAAS_INVESTOR_TAB.expert && (
                <div className={styles.contentSummaryBox}>
                  <div className={styles.contentSummaryTitle}>{intl["saas.expert.summary"]}</div>
                  <div className={styles.contentSummaryDesc}>{data?.summary}</div>
                </div>
              )}
              {type === SAAS_INVESTOR_TAB.updates ? (
                <EditorView value={data?.details ?? ""} />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.details ?? ""
                  }}
                />
              )}
            </div>

            {[SAAS_INVESTOR_TAB.news, SAAS_INVESTOR_TAB.research].includes(type) && (
              <DetailMore
                startTime={data?.publishTime}
                currentId={id}
                type={type === SAAS_INVESTOR_TAB.news ? "news" : "research"}
              />
            )}
          </div>
          <ListRightBox recommendTypes={types} id={id} authorId={data?.authorCustomer?.id} />
        </div>
      </Spin>
    </div>
  );
};

export const PageInvestorDetail = WithShowPdf(React.memo(Detail));
