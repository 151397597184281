import { SAAS } from "@aspen/libs";

export const SAAS_HEADER_MENU_PATHS = [
  {
    id: "saas.menu.venture",
    to: SAAS.VENTURES
  },
  {
    id: "saas.menu.strategy",
    to: SAAS.STRATEGIES
  },
  {
    id: "saas.menu.investor",
    to: SAAS.INVESTOR
  },
  {
    id: "saas.menu.investment",
    to: SAAS.INVESTMENT
  },
  {
    id: "saas.menu.wallet",
    to: SAAS.WALLET
  }
];
