"use client";
import React, { useEffect, useState } from "react";
import styles from "@aspen/theme/saas/venture.module.less";
import { i18nUtil, SAAS_DEFAULT_LIMIT, SAAS_INIT_PAGE_INFO } from "@aspen/libs";
import { ProductItem } from "../../index";
import clsx from "clsx";
import { getSaasStrategyList } from "@aspen/services";
import { AsPagination, message } from "@aspen/ui";
import { IPagerInfo, IResSaasStrategy } from "@aspen/model";
import { Spin } from "antd";
import { useSaasTenant } from "@aspen/hooks";

export const PageStrategies: React.FC = () => {
  const intl = i18nUtil.t();
  const tenant = useSaasTenant();
  const [strategies, setStrategies] = useState<IResSaasStrategy[]>([]);
  const [pagerInfo, setPagerInfo] = useState<IPagerInfo>(SAAS_INIT_PAGE_INFO);
  const [isLoading, setIsLoading] = useState(true);

  const fetchStrategies = (_pagerInfo: IPagerInfo) => {
    setIsLoading(true);
    getSaasStrategyList({
      nextToken: _pagerInfo.nextToken,
      limit: SAAS_DEFAULT_LIMIT
    })
      .then((res) => {
        if (res?.code == "0") {
          const isLastData =
            res?.data?.rows?.length === SAAS_DEFAULT_LIMIT &&
            res?.data?.totalCount === _pagerInfo.page * SAAS_DEFAULT_LIMIT;
          setPagerInfo({
            page: _pagerInfo.page,
            nextToken: isLastData ? "" : res?.data?.nextToken ?? ""
          });
          setStrategies(res?.data?.rows ?? []);
        } else {
          message.error(intl[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchStrategies(SAAS_INIT_PAGE_INFO);
  }, []);

  return (
    <section className={clsx(styles.container, "saasCustomer")}>
      <div className="saasCustomerTop">
        <div
          className={styles.productBg}
          style={{ backgroundImage: `url(${tenant?.backgroundUrl})` }}
        />
        <div className="top">
          <div className="title">
            {intl["saas.welcome"]}
            <div>{tenant?.companyShortName ?? ""}</div>
          </div>
          <div className="headerText">{intl["saas.strategy"]}</div>
        </div>
      </div>
      <div className={styles.contentBox}>
        <Spin spinning={isLoading}>
          <div className={clsx(styles.ventureList, styles.strategyList)}>
            {strategies.length === 0 ? (
              <div className={styles.noData}>{intl["saas.noData"]}</div>
            ) : (
              strategies.map((item) => (
                <div key={item.id} className={styles.ventureListItem}>
                  <ProductItem item={item} productType="strategy" />
                </div>
              ))
            )}
          </div>
        </Spin>
        {strategies.length > 0 && (
          <AsPagination
            pagerInfo={pagerInfo}
            onPageChange={(_pagerInfo) => {
              fetchStrategies(_pagerInfo);
            }}
          />
        )}
      </div>
    </section>
  );
};
